.assistencia__interna{
    max-width: 920px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

	.custom__form{
		@include media-breakpoint-down (lg) {
			max-width: 700px;
			margin-left: auto;
			margin-right: 0px;
		}
		@include media-breakpoint-down (md) {
			padding-left: 100px;
		}
	}
}
.especial__form{
	position: relative;

	.imagem__especial{
		position: absolute;
		right: calc(100% + 15px);
		top: -110px;
		width: 149px;

		@include media-breakpoint-down (md) {
			width: 100px;
			top: -65px;
		}
	}
}
.whats__area{
	display:flex;
	align-items: flex-start;
	column-gap: 15px;

	&:hover{
		text-decoration: none;
	}

	.segura__whats__texto{
		padding-top: 15px;
		font-style: italic;
	}

	@include media-breakpoint-down (md) {
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-top: 30px;

	}
}
.segura__whats__titulo{
	margin-bottom: 8px;
}
.segura__whats__icone{
	.btn__whatsapp__wrapper{
		width: 60px;
		height: 60px;
		border-radius: 50%;;
		background-color: #25D366;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 40px;
		line-height: 40px;
		z-index: 800;

		svg{
			width: 1em;
			height: 1em;
			fill: #FFF;
		}
	}
}
.v-file{
	min-height: 50px;
	margin-top: 5px;
	margin-bottom: 5px;

	.v-file-input,
	.v-file-label{
		min-height: 50px;
	}
	.v-file-label{
		background-color: #e7e7e9;
		border: none;
		display: flex;
		align-items: center;
		border-radius: 25px 0px 0px 25px;
		margin-bottom: 0px;
		padding-left: 0px;
	}
	.fa-paperclip{
		width: 50px;
		height: 50px;
		display: flex;
		min-width: 50px;
		align-items: center;
		justify-content: center;
		background-color: #009FE3;
		color: #fff;
		border-radius: 50%;
		font-size: 22px;
		line-height: 22px;
	}
	.v-file-output{
		font-style: italic;
		padding-left: 15px;
		font-size: 13px;
		line-height: 15px;
		color: #000;
	}
}
.flex__botao__assist{
	align-items: flex-start;
	@include media-breakpoint-up (lg) {
		display: grid;
		grid-template-columns: 1fr 450px;
	}
}
.assistencia__box{
    display: flex;
    margin-bottom: 15px;
}
.assistencia__icone{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background-color: #000;
    color: #fff;
    min-width: 16px;
    font-size: 10px;
    line-height: 10px;
    margin-right: 10px;
    border-radius: 50%;
    padding-bottom: 1px;
}
.assistencia__titulo{
    font-family: 'montserratbold';
}
.assitencia__row{
    margin-bottom: 10px;
}
.assistencia__links{
    display: flex;
    align-items: center;
}
.assistencia__sociais{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;

    a{
        width: 28px;
        height: 28px;
        max-width: 28px;
        max-height: 28px;
        border-radius: 50%;
        border: 1px solid #000;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 14px;
        margin-right: 8px;
        transition: all 0.3s linear;

        &:hover{
            background-color: #009FE3;
            border: 2px solid #009FE3;
            text-decoration: none;
            color: #fff;
        }
    }
}

#banner__carousel{
    max-width: 1920px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    overflow: hidden;

    .carousel-inner{
        width: 160%;
        max-width: 1920px;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
    }
    .carousel-indicators{
        li{
            position: relative;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: none;
            margin: 0px 3px;
            opacity: 1;

            &.active{
                &:after{
                    opacity: 1;
                }
            }

            &:after{
                width: 20px;
                height: 20px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                border: 1px solid #fff;
                content: '';
                border-radius: 50%;
                opacity: 0;
                transition: all 0.3s linear;
            }
        }
    }
}
.categorias__menu{
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
    font-family: 'montserratsemibold';

    a{
        display: block;
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid #fff;
        transition: all 0.3s linear;

        &:hover{
            text-decoration: none;
            background-color: darken(#009FE3, 10%);
            color: #fff;
        }
    }
    .subcategoria__menu{
        a{
            &:hover{
                background-color: darken(#009FE3, 15%);
                text-decoration: none;
                color: #fff;
            }
        }
    }
    .subcategoria__control{
        position: relative;

        button{
            position: absolute;
            width: 20px;
            height: 20px;
            top: calc(50% - 10px);
            right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: none;
            background-color: #fff;
            transition: all 0.3s linear;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;

            span{
                margin-top: 1px;
            }

            &[aria-expanded="true"] {
                transform: rotate(90deg);
            }
        }
    }
}
.categoria__item{
    a{
        display: flex;
        align-items: center;

        img{
            margin-right: 5px;
        }
    }
}
.subcategoria__menu{
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
    color: #fff;
    background-color: darken(#009FE3, 10%);

    a{
        border-bottom: 1px solid #fff;
    }
}
.segura__categoria{
    background-color: #009FE3;

    .container{
        display: flex;
        justify-content:  space-between;
    }
}
.abrir__categ{
    display: flex;
    min-width: 180px;
    padding: 20px 10px;
    background-color: #009FE3;
    color: #fff;
    border: none;
    align-items: center;
    font-family: 'montserratextrabold';
    font-size: 13px;
    line-height: 13px;
    text-align: left;

    @include media-breakpoint-up (lg) {
        margin-right: 10px;
    }

    &:before{
        width: 8px;
        height: 30px;
        content: '';
        display: block;
        background-image: url('../images/bolinha.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        margin-right: 10px;
    }
}
.seta__right,
.seta__left{
    width: 13px;
    height: 12px;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: auto;
    background-color: #009FE3;
    display: block;
    transition: all 0.3s linear;
}
.seta__left{
    mask-image: url('../images/seta__left.png');
}
.seta__right{
    mask-image: url('../images/seta__right.png');
}
.info__card{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'montserratbold';
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: #009FE3;

    &:hover{
        color: #009FE3;
    }

    img{
        margin-right: 10px;
    }
}
.info{
    padding: 30px 0px;
    overflow: hidden
}
.info__owl.owl-carousel{
    .owl-nav{
        .owl-dots{
            display: none;
        }
        button.owl-prev,
        button.owl-next{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        button.owl-prev{
            left: -25px;

            @include media-breakpoint-down (xs) {
                left: -15px;
            }
        }
        button.owl-next{
            right: -25px;

            @include media-breakpoint-down (xs) {
                right: -15px;
            }
        }
    }
    .owl-stage{
        display: flex;
        align-items: center;
    }
}
.titulo__oculto{
    font-size: 0;
    width: 1px;
    height: 1px;
    display: inline-block;
    overflow: hidden;
    position: absolute!important;
    border: 0!important;
    padding: 0!important;
    margin: 0!important;
    clip: rect(1px,1px,1px,1px);
}



.produto__card{
    max-width: 256px;
    width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .produto__card__link{
        &:hover{
            text-decoration: none;

            .produto__card__imagem{
                &:after{
                    opacity: 0.3;
                }
            }
        }
    }

    .produto__card__imagem{
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;
        text-align: center;

        img{
            border: 1px solid #d1d1d1;
        }

        &:after{
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: #009FE3;
            opacity: 0;
            transition: all 0.3s linear;
        }

        .produto__tags{
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 10;

            .produto__tag{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #009FE3;
                color: #fff;
                font-family: 'montserratmedium';
                font-size: 13px;
                line-height: 13px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin: 3px 0px;

                &.tag__new{
                    font-size: 11px;
                    line-height: 11px;
                }
            }
        }
    }
}
.produto__card__titulo{
    font-family: 'montserratbold';
    color: #000;
    font-size: 15px;
    line-height: 15px;
    --linhas: 3;
	min-height: 45px;
    margin-bottom: 8px;
    text-align: center;
}

.produto__card__disponibilidade{
    font-family: 'montserratbold';
    color: #666;
    font-size: 15px;
    line-height: 15px;
    --linhas: 1;
    margin-bottom: 5px;
    text-align: center;
}

.produto__valor__original{
    font-family: 'montserratlight';
    font-size: 12px;
    line-height: 12px;
    color: #666666;
    text-decoration: line-through;
}
.produto__valor__atual{
    font-family: 'montserratextrabold';
    color: #000;
    font-size: 16px;
    line-height: 16px;

    @include media-breakpoint-only (xl) {
        margin-left: 5px;
    }
}
.produto__card__valor{
    display: flex;
    align-items: flex-end;
    justify-content:  center;
    text-align: center;

    @include media-breakpoint-down (lg) {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .produto__valor__atual{
            margin-top: 2px;
        }
    }
}
.produto__card__parcela{
    text-align: left;
    font-family: 'montserratregular';
    font-size: 13px;
    line-height: 15px;
    color: #666666;
    margin-top: 3px;
	text-align: center;
	margin-bottom: 5px;
}
.produto__card__btns{
    display: flex;
    // grid-template-columns: 30% 70%;
	justify-content: space-between;
    column-gap: 10px;
    margin-top: auto;

    .produto__lupa{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        color: #fff;
        font-size: 24px;
        line-height: 24px;
        min-height: 46px;
        transition: all 0.3s linear;
		width: calc(30% - 5px);

        &:hover{
            background-color: #009FE3;
        }

        &:after{
            content: '';
            position: relative;
            width: 22px;
            height: 22px;
            background-image: url(../images/lupa__icone.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto;
        }
    }
    .carrinho__card__botao{
        display: flex;
        align-items: center;
        background-color: #000;
        color: #fff;
        font-family: 'montserratbold';
        transition: all 0.3s linear;
        justify-content: center;
        text-transform: uppercase;
        padding: 10px;
        min-height: 46px;
		width: calc(70% - 5px);

        &:hover{
            background-color: #009FE3;
            text-decoration: none;
        }

        &:before{
            width: 25px;
            height: 22px;
            background-image: url(../images/carrinho__card.png);
            background-repeat: no-repeat;
            content: '';
            display: block;
            background-position: center;
            background-size: auto;
            margin-right: 5px;
			min-width: 25px;
        }
    }

    .carrinho__card__orcamento{
        display: flex;
        align-items: center;
        background-color: #000;
        color: #fff;
        font-family: 'montserratbold';
        transition: all 0.3s linear;
        justify-content: center;
        text-transform: uppercase;
        padding: 10px;
        min-height: 46px;

        &:hover{
            background-color: #009FE3;
            text-decoration: none;
        }
    }
}
.row__produtos{
    .col-lg-3{
        margin-bottom: 40px;
        display: flex;
    }
}
.banner__link{
    padding: 20px 0px 40px 0px;

    .banner__link__card{
        display: block;
        max-width: 1100px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}
.produtos__2{
    padding-bottom: 40px;
}
.produtos__3{
    padding-bottom: 100px;
}
.produtos__owl.owl-carousel{
    display: flex !important;
    flex-direction: column;

    .owl-nav{
        order: 1;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;

        button.owl-prev,
        button.owl-next{
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 3px;
            border-radius: 50%;
            border: 1px solid #009FE3;
            transition: all 0.3s linear;

            &:hover{
                background-color: #009FE3;

                .seta__left,
                .seta__right{
                    background-color: #fff;
                }
            }
        }
    }
    .owl-stage-outer{
        order: 2;
    }
}
@include media-breakpoint-up (lg) {
    .segura__categoria__mobile{
        display: none;
    }
    .main__categorias{
        .subcategoria__control{
            display: flex;

            button{
                display: none;
            }
        }
        .categoria__item{
            position: relative;

            &:hover{
                .subcategoria__menu{
                    display: block;
                }
            }
        }
        .subcategoria__menu{
            position: absolute;
            top: 100%;
            left: calc(50% - 125px);
            width: 250px;
            background-color: #009FE3;
            z-index: 1080;
            box-shadow: 0px 0px 5px rgba(#000, 0.5);

            li{
                border-bottom: 1px solid rgba(#fff, 0.5);

                &:last-child{
                    border-bottom: none;
                }

                a{
                    display: block;
                    padding: 10px;
                    text-align: center;
                    color: #fff;
                    border-bottom: none;
                }
            }
        }
    }
    .segura__categ{
        position: fixed;
        width: 250px;
        height: 100%;
        overflow-y: auto;
        z-index: 1080;
        top: 0px;
        left: -250px;
        background-color: #009FE3;
        color: #fff;
    }
    .categoria__destaque__desktop{
        display: flex;
        flex-grow: 1;
        width: 100%;
    }
    .main__categorias__desktop{
        display: flex;
        width: 100%;
    }
    .main__categorias{
        display: flex;
        list-style: none;
        padding-left: 0px;
        margin-bottom: 0px;
        justify-content: space-between;
        width: 100%;
        font-family: 'montserratmedium';
        color: #fff;
        font-size: 13px;
        line-height: 13px;

        .categoria__item{
            display: flex;
            margin: 0px 3px;

            .subcategoria__menu{
                a{
                    &:hover{
                        background-color: darken(darken(#009FE3, 10%), 10%);
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }

            a{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px;
                transition: all 0.3s linear;
                font-family: 'montserratmedium';
                font-size: 13px;
                line-height: 15px;

                img{
                    margin-right: 3px;
                }

                &:hover{
                    color: #fff;
                }
            }
        }
    }
    .segura__categ{
        position: fixed;
        left: -250px;
        width: 250px;
        height: 100%;
        overflow-y: auto;
        z-index: 1080;
        background-color: #009FE3;
        color: #fff;
        transition: all 0.5s linear;
        top: 0px;

        &.shown{
            left: 0px;
        }
    }
}
@include media-breakpoint-down (md) {
    .segura__categ{
        position: fixed;
        left: -250px;
        width: 250px;
        height: 100%;
        overflow-y: auto;
        z-index: 1080;
        background-color: #009FE3;
        color: #fff;
        transition: all 0.5s linear;
        top: 0px;

        &.shown{
            left: 0px;
        }
    }
    .segura__categoria__desktop{
        display: none;
    }
    .main__categorias,
    .categorias__menu{
        list-style: none;
        padding-left: 0px;
        margin-bottom: 0px;
        font-family: 'montserratsemibold';
        background-color: #009FE3;
        color: #fff;

        a{
            display: flex;
            width: 100%;
            padding: 10px;
            border-bottom: 1px solid darken(#009FE3, 10%);
            font-family: 'montserratsemibold';
            color: #fff;
            font-size: 13px;
            line-height: 15px;

            &:hover{
                text-decoration: none;
                color: #fff;
                background-color: #009FE3;
            }
        }
        .subcategoria__menu{
            a{
                background-color: darken(#009FE3, 10%);
                color: #fff;
                border-bottom: 1px solid #fff;

                &:hover{
                    color: #fff;
                    background-color: darken(#009FE3, 10%);
                    text-decoration: none;
                }
            }
        }
        .subcategoria__control{
            position: relative;

            button{
                position: absolute;
                width: 20px;
                height: 20px;
                top: calc(50% - 10px);
                right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                border: none;
                background-color: #fff;
                color: #000;
                transition: all 0.3s linear;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;

                span{
                    margin-top: 1px;
                }

                &[aria-expanded="true"] {
                    transform: rotate(90deg);
                }
            }
        }
    }
    .abrir__categ{
        padding: 14px 10px;
        width: 100%;
        background-color: #009FE3;
        color: #fff;
        border-bottom: 2px solid #fff;
        justify-content: center;

        &:hover{
            color: #fff;
        }
    }
}
.segura__relacionados{
	padding: 40px 0px 70px 0px;
	border-bottom: 2px solid rgba(#000, 0.1);
	margin-bottom: 40px;

	.titulo__interna{
		margin-bottom: 5px;
	}
	.owl-dots{
		display: none !important;
	}
}

// Margins
@include margin-variant(10);
@include margin-variant(30);
@include margin-variant(40);
@include margin-variant(60);
@include margin-variant(70);

[data-owl]{

	&:not(.ready){
		display: none;
	}

	&.disabled{
		cursor: not-allowed;
		opacity: 0.6;
	}
}


.slick-slider:not(.slick-initialized) {
	opacity: 0;
}

.modal__orcamento{
	.modal-header{
		background-color: $primary;
		color: #FFF;
	}

	.modal-title{
		font-family: "montserratbold";
	}

	.modal-footer{
		display: grid;
		grid-template-columns: repeat(2, calc(50% - 15px));
		grid-gap: 30px;
		background: #DDD;
	}

	.form-group{
		position: relative;

		.form-control{
			border-color: var(--context, #ccc);

			&::placeholder{
				color: transparent;
			}
		}

		.label{
			font-size: var(--font, 1rem);
			position: absolute;
			top: var(--top, 11px);
			left: 10px;
			display: inline-block;
			padding: 0 3px;
			background-color: #FFF;
			transition: all .3s linear;
			margin-bottom: 0;
			color: var(--context, inherit);
			transform: translateY(var(--y, 0));
		}

		&.focus {
			--context: #{$primary};
			--top: 0;
			--y: -50%;
			--font: 13px;

			$states: (
				'success': $success,
				'warning': $warning,
				'error'  : $danger
			);

			@each $state, $value in $states {
				&.has__#{$state} {
					--context: #{$value};
				}
			}
		}
	}
}
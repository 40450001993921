.row__form{
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.login__form,
.login--cadastro__form{
    .form-group{
        margin-bottom: 10px;
    }
}
.login__desc{
    margin-bottom: 18px;
    padding-top: 5px;
    font-family: 'montserratmedium';
    color: #000;
    padding-left: 30px;
}
.esqueci__link{
    font-family: 'montserratregular';
    font-size: 13px;
    line-height: 15px;
    color: #000;
    padding-top: 7px;
    display: inline-flex;
    position: relative;
    align-items: center;
    transition: all 0.3s linear;
    font-style: italic;
}
.login__btn{
    padding-top: 5px;
}
.login--cadastro__btn{
    padding-top: 23px;
}
.titulo__login{
    font-family: 'montserratbold';
    color: #000;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 22px;
}
@include media-breakpoint-down (md) {
    .login__btn{
        margin-bottom: 40px;
    }
}
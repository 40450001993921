.bg__menu{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    animation: fadeIn 0.5s linear;
    z-index: 1050;
    background-color: rgba(#000, 0.6);

    &.hide{
        animation: fadeOut 0.5s linear;
    }
}
.blog__link{
	display: block;
	padding: 5px;
	font-size: 12px;
	line-height: 14px;
	color: #000;
    font-family: 'montserratmedium';

	&:hover{
		text-decoration: underline;
		color: #009FE3;
	}

	@include media-breakpoint-down (md) {
        display: block;
        width: 100%;
        padding: 8px 10px;
        font-family: 'montserratsemibold';
        border-bottom: 1px solid #000;
        font-size: 13px;
        line-height: 17px;

        &:hover{
            color: #000;
        }
	}
}
.pesquisa__form{
    display: flex;
    overflow: hidden;
    background-color: #fff;
    align-items: center;

    .pesquisa__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #009FE3;
        color: #fff;
        min-width: 40px;
        width: 40px;
        height: 40px;
        font-size: 20px;
        line-height: 20px;
        border: 1px solid #000;
    }
    .pesquisa__input{
        display: block;
        width: 100%;
        font-family: 'montserratmedium';
        font-size: 13px;
        line-height: 13px;
        padding: 13px 20px 12px 20px;
        border: none;
        background-color: #fff;
        color: #000;
        font-style: italic;
        border: 1px solid #000;
        border-right: none;

        &::placeholder{
            color: #000;
        }

        &:focus{
            outline: none;
        }
    }
    label{
        display: block;
        width: 100%;
        margin-bottom: 0px;
    }
}
.segura__login__topo{
    font-size: 13px;
    line-height: 15px;
    font-family: 'montserratbold';
    position: relative;
    color: #000;

    .login__icone{
        margin-right: 8px;
        min-width: 30px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 50%;
    }
    .login__toggler{
        display: flex;
        align-items: center;

        &:hover{
            text-decoration: none;
        }
    }
    .dropdown{
        display: none;
    }

    &.logado{
        .login__toggler{
            display: none;
        }
        .dropdown{
            display: block;
        }
    }
}
.logado__toggler{
	display: flex;
	align-items: center;
	border: none;
	background-color: transparent;
	color: #000;

	&:focus{
		outline: none;
	}
}
.encerrar__item{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .encerrar__link{
        width: 198px;
        max-width: 100%;
        height: 50px;
        background-color: #000;
        color: #fff;
        font-family: 'montserratsemibold';
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
        transition: all 0.3s linear;

        &:hover{
            background-color: darken(#009FE3, 10%);
            color: #fff;
            text-decoration: none;
        }
    }
}
.logado__nome{
    padding: 28px 20px 22px 20px;
    background-color: #009FE3;
    color: #fff;
    font-family: 'montserratlight';
    font-size: 13px;
    line-height: 15px;
}
.logado__item{
    padding: 0px 10px;

    .logado__link{
        display: block;
        padding: 22px 10px 20px 10px;
        border-bottom: 1px solid rgba(#000, 0.1);
        font-size: 14px;
        line-height: 14px;
        font-family: 'montserratregular';
        color: #000;

        &:hover{
            text-decoration: none;
            font-family: 'montserratsemibold';
            color: #009FE3;
        }
    }
}
.topo__pesquisa{
    position: fixed;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1080;
    max-width: 900px;
    width: 90%;
    transition: all 0.5s linear;

    &.shown{
        top: 48vh;
    }
}
.carrinho__icone{
    margin-right: 10px;
    min-width: 45px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    font-family: 'montserratbold';
    font-size: 13px;
    line-height: 15px;
    color: #000;

    .carrinho__numero{
        font-size: 11px;
        line-height: 11px;
        background-color: #009FE3;
        color: #fff;
        padding: 5px 3px 4px 3px;
        border-radius: 10px;
        position: absolute;
        top: 0px;
        right: -5px;
        z-index: 10;
        font-family: 'montserratbold';
    }
}
.carrinho__link__topo{
    display: flex;
    align-items: center;
    font-family: 'montserratbold';
    font-size: 13px;
    line-height: 15px;
    color: #000;

    &:hover{
        color: #000;
    }
}
.segura__menu{
    display: flex;
    align-items: center;
}
.sociais__nav{
    display: flex;
    justify-content: center;
    align-items: center;

    a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #000;
        color: #000;
        font-size: 12px;
        line-height: 12px;
        transition: all 0.3s linear;
        margin: 0px 2px;

        &:hover{
            background-color: #009FE3;
            border-color: #009FE3;
            color: #fff;
            text-decoration: none;
        }
    }
}
@include media-breakpoint-up (lg) {
    .topo__main{
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .main__pesquisa{
                flex-grow: 1;
                margin: 0px 24px;
                max-width: 540px;
                width: 100%;
            }
        }
    }
    .topo__mobile,
    .topo__pesquisa{
        display: none;
    }
    .main__cima{
        font-size: 12px;
        line-height: 14px;
        font-family: 'montserratsemibold';
        color: #949494;

        .menu__titulo{
            margin-right: 8px;
        }

        .main__menu{
            a{
                color: #000;
                display: inline-block;
                margin: 0px 5px;
                font-family: 'montserratmedium';

                &:hover{
                    color: #009FE3;
                    text-decoration: underline;
                }
            }
        }

        .container{
            border-bottom: 1px solid #d1d1d1;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
    .main__sociais{
        display: flex;
        align-items: center;

        .sociais__titulo{
            margin-right: 8px;
        }
    }
    .main__logo{
        padding: 18px 0px;
    }
    .main__info{
        display: flex;
        align-items: center;

        .segura__login__topo,
        .segura__carrinho__topo{
            margin: 0px 5px;
        }
    }
    .logado__menu{
        min-width: 256px;
        max-width: 100%;
        left: calc(50% - 128px) !important;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000, 0.2);
    }
    .logado__toggler{
        position: relative;
        padding: 20px 0px;

        &:after{
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 15px solid #009FE3;
            content: '';
            position: absolute;
            left: calc(50% - 14px);
            bottom: -3px;
            display: none;
        }

        &[aria-expanded="true"] {
            &:after{
                display: block;
            }
        }
    }
}
@include media-breakpoint-only (lg) {
    .main__sociais{
        .sociais__titulo{
            display: none;
        }
    }
}
@include media-breakpoint-down (md) {
    .topo__main{
        position: fixed;
        width: 250px;
        height: 100%;
        overflow-y: auto;
        top: 0px;
        left: -250px;
        transition: all 0.5s linear;
        z-index: 1080;
        background-color: #fff;
        display: flex;
        flex-direction: column;

        .main__pesquisa{
            display: none;
        }

        .main__cima{
            order: 2;
        }
        .main__meio{
            order: 1;
        }

        &.shown{
            left: 0px;
        }

        .container{
            padding: 0px;
        }
    }
    .topo__mobile{
        background-color: #fff;

        .container{
            padding-top: 15px;
            padding-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .main__logo{
        padding: 20px;
        text-align: center;
    }
    .logado__toggler,
    .login__toggler{
        padding: 10px 0px;
        width: 100%;
        justify-content: center;
    }
    .segura__login__topo{
        border-bottom: 1px solid #000;
    }
    .carrinho__link__topo{
        width: 100%;
        justify-content: center;
        padding: 10px 0px;
        border-bottom: 1px solid #000;
    }
    .logado__menu{
        float: none !important;
        position: relative !important;
        top: 0px !important;
        transform: none !important;
        padding: 0px;
        border: none;
        border-radius: 0px;
        margin: 0px;
        width: 100%;
        color: #000;

        .logado__link{
            font-family: 'montserratsemibold';
        }
    }
    .mbl__toggler{
        border: none;
        background-color: transparent;
        font-size: 26px;
        line-height: 26px;
        color: #000;

        &:focus{
            outline: none;
        }
    }
    .menu__titulo{
        background-color: #009FE3;
        color: #fff;
        font-family: 'montserratsemibold';
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        padding: 10px;
    }
    .segura__menu{
        flex-direction: column;
    }
    .menu__titulo{
        width: 100%;
    }
    .main__menu{
        width: 100%;

        a{
            display: block;
            width: 100%;
            padding: 8px 10px;
            font-family: 'montserratsemibold';
            border-bottom: 1px solid #000;
        }
    }
    .sociais__titulo{
        text-align: center;
        font-family: 'montserratsemibold';
        background-color: #009FE3;
        color: #fff;
        padding: 10px;
        margin-bottom: 10px;
    }
    .sociais__nav{
        a{
            background-color: #009FE3;
            color: #fff;
            border-color: #009FE3;
        }
    }
}

.download__card{
    padding: 24px 28px;
    background-color: #ededed;
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .download__card__icone{
        display: block;
        margin-left: 10px;
        min-width: 32px;
    }

    @include media-breakpoint-down (md) {
        flex-direction: column;

        .download__card__icone{
            margin-left: 0px;
            margin-top: 15px;
        }
    }
}
.download__card__titulo{
    font-family: 'montserratbold';
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 5px;
}
.segura__cadastro__nav{
	font-family: 'montserratsemibold';
	font-weight: 600;
	font-size: 13px;
	line-height: 13px;
	color: #000;
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	letter-spacing: 0.6px;

	.radio__opcao{
		margin-right: 20px;
		font-family: 'montserratsemibold';
	}
}
.titulo__form{
	font-family: 'montserratbold';
	font-size: 20px;
	line-height: 24px;
	color: #000;
	margin-bottom: 16px;
}
.cadastro__header{
	display: flex;
	justify-content: space-between;
    margin-bottom: 30px;
    align-items: flex-end;
}
.cadastro__titulo{
	font-family: 'montserratbold';
	font-size: 20px;
	line-height: 24px;
	color: #000;
}
.obrigatorio{
	font-size: 13px;
	line-height: 17px;
    color: #000;
    font-family: 'montserratregular';
}
.cadastro__form{
    max-width: 960px;
	width: 100%;
	margin-right: auto;
    margin-left: auto;

    .segura__genero{
        font-family: 'montserratmedium';
        color: #000;
        font-size: 13px;
        line-height: 13px;
    }
    
    .form-group{
        margin-bottom: 10px;
    }
	.row{
		max-width: 920px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
}
.segura__cadastro__conteudo{
    max-width: 1000px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.endereco__cadastro__titulo{
	font-family: 'montserratbold';
	font-size: 20px;
	line-height: 24px;
	color: #000;
	padding-top: 20px;
	margin-bottom: 10px;
}
.endereco__cadastro__desc{
	font-size: 14px;
	line-height: 18px;
    color: #000;
    font-family: 'montserratmedium';
	margin-bottom: 30px;
	font-weight: 600;
	font-size: 13px;
	line-height: 17px;
}
.segura__cadastro__btns{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;

	.btn__interna{
        &:nth-child(1) {
            margin-right: 10px;
        }
    }
}
.segura__check{
	padding-top: 30px;
	font-size: 13px;
	line-height: 16px;
	display: flex;
    align-items: center;
    font-family: 'montserratmedium';
	font-weight: 600;
    color: #000;
}
.segura__genero{
	display: flex;
	align-items: center;
	height: 48px;
    font-family: 'montserratregular';
    color: #000;
    padding-left: 10px;
    font-weight: 600;
    letter-spacing: 0.6px;

    @include media-breakpoint-down (xs) {
        height: auto;
    }

	span{
		margin-right: 5px;
	}
}
.radio__input{
	position: fixed;
	left: -100vw;

	&:checked{
		~ .radio__label{

			// &:before{
			// 	border-color: #000;
			// 	background-color: #fff;
			// }
			&:after{
				opacity: 1;
			}
		}
	}
}
.radio__label{
	position: relative;
	margin-bottom: 0px;
	padding-left: 25px;
	display: block;
	margin-left: 10px;
	cursor: pointer;
	color: #000;
	font-family: 'montserratmedium';
	font-size: 13px;
	line-height: 13px;

	&:before{
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 2px solid #000;
		transition: all 0.3s linear;
		background-color: transparent;
		z-index: 10;
	}

	&:after{
		content: '';
		position: absolute;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 4px solid #fff;
		background-color: #000;
		top: 50%;
		left: 0px;
		transform: translateY(-50%);
		transition: all 0.3s linear;
		opacity: 0;
		z-index: 5;
	}
}
.check__label__cadastro{
	padding-left: 30px;
	position: relative;
	cursor: pointer;
	margin-bottom: 40px;

	&:before{
		content: '';
		position: absolute;
		top: 44%;
		transform: translateY(-50%);
		left: 0px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 2px solid #000;;
		transition: all 0.3s linear;
		z-index: 10;
	}

	&:after{
		content: '';
		position: absolute;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 4px solid #fff;
		background-color: #000;
		top: 44%;
		left: 0px;
		transform: translateY(-50%);
		transition: all 0.3s linear;
		opacity: 0;
		z-index: 5;
	}
}
.cadastro__check{
	position: fixed;
	left: -100vw;

	&:checked{
		& ~ .check__label__cadastro{
			// &:before{
			// 	border-color: #000;
			// 	background-color: #fff;
			// }
			&:after{
				opacity: 1;
			}
		}
	}
}
.pessoa__radio{
	position: fixed;
	left: -200vw;

	& ~ .segura__cadastro__nav{
			font-family: 'montserratbold';
			font-size: 13px;
			line-height: 17px;
			color: #000;
			display: flex;
			align-items: center;
			margin-bottom: 25px;

		.pessoa__custom{
			padding: 0px;
			border: none;
			padding-left: 28px;
			margin: 0px 6px;
			position: relative;
			font-size: 14px;
			line-height: 18px;
			color: #000;
            cursor: pointer;
            margin-bottom: 5px;

			&:hover{
				&:before{
					border-color: #000;
					background-color: #fff;
				}
			}

			&:before{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0px;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				border: 2px solid #000;
				transition: all 0.3s linear;
			}

			&:after{
				content: '';
				position: absolute;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				background-color: #000;
				border: 5px solid #fff;
				top: 50%;
				left: 0px;
				transform: translateY(-50%);
				transition: all 0.3s linear;
				opacity: 0;
			}
		}
	}
}
@include media-breakpoint-up (lg) {
	.cadastro__header{
		.cadastro__titulo,
		.obrigatorio{
			max-width: 50%;
			width: 100%;
		}
	}
}
@include media-breakpoint-down (xs) {
	.segura__cadastro__btns{
		flex-direction: column;
		align-items: center;

		.interna__btn{
			margin: 5px 0px;
		}
	}
	.segura__cadastro__nav{
		flex-direction: column;
		align-items: center;

		span{
			margin-right: 0px;
		}

		.nav-item{
			margin: 5px 5px;
		}
	}
	.segura__genero{
		flex-direction: column;
		align-items: flex-start;

		span{
			margin-bottom: 5px;
		}

		.radio__label{
			margin-bottom: 5px;
		}
	}
}
@keyframes fadeCustom{
	0%{
		opacity: 0;
	}
	100%{
		opacity: 100%;
	}
}

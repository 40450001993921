.rodape__conteudo{
    background-color: #939393;
    color: #fff;
    padding-top: 36px;


    .container{
        padding-top: 20px;
        border-bottom: 1px solid #fff;
    }
}
.rodape__atendimento__titulo{
	font-family: 'montserratbold';
	color: #fff;
	margin-bottom: 2px;
	font-size: 15px;
	line-height: 18px;
    margin-bottom: 16px;
}
.rodape__atendimento__telefone{
	font-family: 'montserratbold';
	color: #fff;
	font-size: 30px;
	line-height: 34px;
    margin-bottom: 16px;
}
.rodape__contato{
	margin-bottom: 14px;
	font-size: 13px;
	line-height: 17px;
	color: #fff;
    font-family: 'montserratmedium';
}
.rodape__titulo{
	font-family: 'montserratbold';
	font-size: 15px;
	line-height: 19px;
	color: #fff;
	margin-bottom: 15px;
}
.rodape__nav{
	display: flex;
	flex-direction: column;
	color: #fff;
	font-family: 'montserratmedium';
	font-size: 13px;
	line-height: 15px;
    align-items: flex-start;

	a{
		display: inline-block;
		margin-bottom: 14px;
	}
}
.rodape__col__desc{
	font-family: 'montserratregular';
	text-align: justify;
	font-size: 13px;
	line-height: 15px;
}
.pagamento__img{
	margin-bottom: 0px;
    padding-left: 24px;
}
.pagamento__parcelamento{
	font-family: 'montserratlight';
}
.pagamento__secao{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;

    .pagamentos__header{
        text-align: right;
        font-size: 14px;
        line-height: 18px;

        .rodape__pag__titulo{
            font-family: 'montserratsemibold';
        }
    }
}
.rodape__sociais{
    padding-top: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
	flex-wrap: wrap;

    a{
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 2px solid #fff;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 16px;
        margin: 4px;
        transition: all 0.3s linear;

        &:hover{
            background-color: #009FE3;
            border: 2px solid #009FE3;
            text-decoration: none;
            color: #fff;
        }
    }
}
.rodape__creditos{
    background-color: #939393;
    color: #fff;
    text-align: center;
    padding: 18px 0px;
    font-size: 12px;
    line-height: 14px;

    span{
        font-family: 'montserratbold';
    }
}
.gv8__box{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;

    svg{
        margin-left: 5px;
        color: #fff;
    }
}
@include media-breakpoint-down (md) {
    .rodape__titulo{
        padding-top: 30px;
    }
    .pagamento__secao{
        flex-direction: column;

        .pagamentos__header{
            margin-bottom: 20px;
        }
    }
    .rodape__conteudo{
        text-align: center;

        .rodape__nav{
            align-items: center;
        }
        .rodape__sociais{
            justify-content: center;
        }
        .rodape__atendimento{
            text-align: center;
        }
    }
}

.endereco__interna{
    max-width: 940px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.endereco__item{
	margin-bottom: 30px;
	font-family: 'montserratregular';
	font-size: 14px;
	line-height: 24px;
	color: #000000;
}
.endereco__titulo{
	margin-bottom: 20px;
	font-family: 'montserratextrabold';
	color: #000000;
}
.endereco__btns{
	display: flex;
	flex-wrap: wrap;
	padding-top: 10px;

	.interna__btn{
		margin-right: 10px;
		margin-bottom: 10px;
	}
}
.v-select{
    &.show{
        z-index: 600;
    }
}
.endereco__form,
.custom__form{
	.v-select-output{
		display: block;
		width: 100%;
		height: auto;
		padding: 16px 24px 14px 24px;
		background-color: #e7e7e9;
		font-family: 'montserratitalic';
		font-size: 13px;
		line-height: 15px;
		color: #000;
		border: none;
		border-radius: 0px;

		&:focus{
			box-shadow: none;
		}

		&:before{
			margin-top: 4px;
		}
	}
	.v-options{
		font-size: 13px;
		line-height: 15px;
		margin: none;
		max-height: calc(36px * 4);
		border-radius: 0px;
        z-index: 500;

		li{
			&.selected{
				background-color: #000;
			}
		}
	}
}
.endereco__listagem{
	padding-bottom: 30px;
}
.endereco__listagem__detalhe{
	padding-bottom: 20px;
	border-bottom: 11px solid #EEEEEE;
	margin-bottom: 20px;
}
.form__item{
	display: flex;
	flex-direction: column;
	font-size: 14px;
	line-height: 18px;
}
.endereco__form{
	max-width: 930px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
    padding-top: 30px;
    
    .form-group{
        margin-bottom: 8px;
    }
}
.endereco__detalhe__btn{
	padding-top: 20px;
	display: flex;
	justify-content: flex-end;
}
.interna__endereco{
	max-width: 930px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
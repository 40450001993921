.interna{
    padding: 76px 0px 100px 0px;
}
.btn__interna{
    display: inline-flex;
    min-width: 250px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    background-color: #009FE3;
    color: #fff;
    font-family: 'montserratbold';
    font-size: 14px;
    line-height: 14px;
    border: none;
    transition: all 0.3s linear;
    margin: 0px 5px;
    padding: 5px 20px;
    border: 2px solid #009FE3;

	&.destaque{
		background-color: green;
		min-width: 100%;
		font-size: 20px;
		line-height: 24px;
		border-color: green;
	}

    &.voltar__btn{
        background-color: #009FE3;
        min-width: 154px;

        &:hover{
            text-decoration: none;
            background-color: darken(#009FE3, 10%);
            border-color: darken(#009FE3, 10%);
            color: #fff;
        }
    }
    &.alt__btn{
        background-color: #000;
        color: #fff;
        border-color: #000;

        &:hover{
            text-decoration: none;
            background-color: lighten(#000, 10%);
            border-color: lighten(#000, 10%);
            color: #fff;
        }
    }
    &:focus{
        outline: none;
    }
    &:hover{
        text-decoration: none;
        background-color: darken(#009FE3, 10%);
        border-color: darken(#009FE3, 10%);
        color: #fff;
    }
}
.titulo__interna{
    font-family: 'montserratbold';
    font-size: 30px;
    line-height: 34px;
    color: #000;
    margin-bottom: 24px;
}
.breadcrump{
    background-color: #009FE3;
    color: #fff;
    font-family: 'montserratlight';
    font-size: 13px;
    line-height: 13px;
    padding: 12px 0px;

    .segura__breadcrump{
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .breadcrump__pagina{
            font-family: 'montserratbold';
            margin-left: 4px;
            font-size: 14px;
            line-height: 14px;
        }
    }
}
.minha__conta__nav{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    a{
        display: block;
        padding: 15px 10px;
        font-size: 15px;
        line-height: 15px;
        max-width: 300px;
        border-bottom: 1px solid rgba(#000, 0.4);
        width: 100%;
    }
}

.interna{
	.btn{
		&:not(.btn__finaliza__compra){
			@extend .btn__interna;
			margin-top: 15px;
		}
	}
}

.produto__figure{
	margin-bottom: 10px;
}
.angulos__txt{
	font-family: 'montserratregular';
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 20px;
	padding-top: 10px;
	color: #000;
}
.row__miniaturas{
	.col__auto{
		margin-bottom: 10px;
		margin-right: 10px;
		margin-left: 10px;

		a{
			display: block;
			position: relative;
            transition: all 0.3s linear;
            box-shadow: 0px 4px 8px rgba(#000, 0.2);
        

			&:after{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				z-index: 2;
				top: 0px;
                left: 0px;
                background-color: #009FE3;
				opacity: 0;
                transition: all 0.3s linear;
			}
		}

		.zoomGalleryActive{
			&:after{
				opacity: 0.6;
			}
		}

		&:first-child{
			margin-left: 0px;
		}
	}
}
.row__produto__interna{
	margin-bottom: 0px;

	.col-lg-6{
		&:first-child{
			position: relative;
			z-index: 4;
		}
		&:last-child{
			position: relative;
			z-index: 3;
		}
	}
}
.rating__stars{
	color: #cacaca;
	i{
		position: relative;
	}
}
.rating__stars .fa-star:after {
  	position: absolute;
	color: #FFCA08;
	left: 0;
}
.rating__stars[data-rating="0.5"] .fas:nth-child(1):after {
  	content: "";
}

.rating__stars[data-rating="1.5"] .fas:nth-child(2):after {
  	content: "";
}

.rating__stars[data-rating="2.5"] .fas:nth-child(3):after {
  	content: "";
}

.rating__stars[data-rating="3.5"] .fas:nth-child(4):after {
 	content: "";
}

.rating__stars[data-rating="4.5"] .fas:nth-child(5):after {
 	content: "";
}

.rating__stars[data-rating="5.5"] .fas:nth-child(6):after {
 	content: "";
}

.rating__stars[data-rating="1"] .fas:nth-child(1),
.rating__stars[data-rating="1.5"] .fas:nth-child(1) {
  	color: #FFCA08;
}

.rating__stars[data-rating="2"] .fas:nth-child(1),
.rating__stars[data-rating="2"] .fas:nth-child(2),
.rating__stars[data-rating="2.5"] .fas:nth-child(1),
.rating__stars[data-rating="2.5"] .fas:nth-child(2) {
  	color: #FFCA08;
}

.rating__stars[data-rating="3"] .fas:nth-child(1),
.rating__stars[data-rating="3"] .fas:nth-child(2),
.rating__stars[data-rating="3"] .fas:nth-child(3),
.rating__stars[data-rating="3.5"] .fas:nth-child(1),
.rating__stars[data-rating="3.5"] .fas:nth-child(2),
.rating__stars[data-rating="3.5"] .fas:nth-child(3) {
  	color: #FFCA08;
}

.rating__stars[data-rating="4"] .fas:nth-child(1),
.rating__stars[data-rating="4"] .fas:nth-child(2),
.rating__stars[data-rating="4"] .fas:nth-child(3),
.rating__stars[data-rating="4"] .fas:nth-child(4),
.rating__stars[data-rating="4.5"] .fas:nth-child(1),
.rating__stars[data-rating="4.5"] .fas:nth-child(2),
.rating__stars[data-rating="4.5"] .fas:nth-child(3),
.rating__stars[data-rating="4.5"] .fas:nth-child(4) {
  	color: #FFCA08;
}
.rating__stars[data-rating="5"] {
  	color: #FFCA08;
}
.zoomContainer{
	position: relative;
	z-index: 10;
}
.produto__detalhe__titulo{
	font-family: 'montserratbold';
	font-size: 30px;
	line-height: 30px;
	color: #000;
	margin-bottom: 10px;
}
.produto__detalhe__codigo{
	font-family: 'montserratregular';
	font-size: 12px;
	line-height: 16px;
	color: #000;
	padding-bottom: 20px;
	border-bottom: 5px solid #e7e7e9;
	margin-bottom: 15px;
}
.produto__detalhe__avaliacao{
	display: flex;
	align-items: center;
	margin-bottom: 15px;

	.rating__stars{
		margin-right: 10px;
	}
}
.avaliacoes__quant{
	font-family: 'montserratregular';
	font-size: 12px;
	line-height: 12px;
	color: #000;
}
.cor__opcao{
    margin: 3px;

    .cor__input{
        position: fixed;
        left: -100vw;

        &:checked{
            & ~ .cor__label{
                border: 2px solid #000;
            }
        }
    }
    .cor__label{
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid transparent;
		background-color: var(--cor);
		cursor: pointer;
        margin: 0px;
        transition: all 0.3s linear;
        box-shadow: 0px 0px 5px rgba(#000, 0.5);
    }
}
.cor__titulo{
    color: #000;
    font-size: 13px;
    line-height: 16px;
    font-family: 'montserratsemibold';
    margin-bottom: 0px;
}
.produto__cor__listagem{
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    flex-wrap: wrap;
}
.produto__comprar{
	margin-right: auto;
	margin-left: 0px;
	width: 100%;
    background-color: #ededed;
    border-radius: 0px;
}
.produto__detalhe__box{
	padding: 14px 32px 12px 32px;
	color: #000;
    border-bottom: 9px solid #FFF;
    text-align: center;

    .row{
        align-items: center;

        @include media-breakpoint-down (md) {
            .col-md-6{
                margin-bottom: 15px;
            }
        }
    }
}
.produto__comprar{
	padding-bottom: 0px;

	.produto__detalhe__box{
		&:last-child{
			border-bottom: 0px;
		}
	}
}
.produto__detalhe__valor{
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: flex-end;
	margin-bottom: 0px;
	font-family: 'montserratbold';
	font-size: 13px;
	line-height: 17px;
	color: #000;

	span{
		margin-right: 5px;
	}
}
.detalhe__dinheiro{
	font-size: 20px;
	font-family: 'montserratbold';
	line-height: 24px;
	color: #000;
}
.produto__detalhe__juros{
	font-family: 'montserratbold';
	font-size: 14px;
	line-height: 16px;
	color: #000;
	text-align: center;
}
.produto__off{
	text-align: center;
	font-size: 18px;
	line-height: 22px;
	font-family: 'montserratbold';
	color: #000;
}
.carrinho__btn__interna{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50px;
	flex-wrap: wrap;
	background-color: transparent;
    color: #000;
    border: 2px solid #000;
	font-family: 'montserratregular';
	font-size: 15px;
	line-height: 15px;
    transition: all 0.3s linear;

	&:hover{
		text-decoration: none;
		color: #fff;
		background-color: #000;
	}

	&:focus{
		outline: none;
	}

	.icon{
		margin-right: 5px;
	}
}
.frete__detalhe{
	display: flex;
	position: relative;
	border: 1px solid #a4a4a4;
    margin-bottom: 3px;
    align-items: center;
    background: #fff;

	&.esgotado__form{
		.frete__btn{
			// min-width: 120px;
			display: inline-block;
			white-space: nowrap;
		}
	}
}
.esgotado__titulo{
	font-family: 'montserratbold';
	font-size: 24px;
	line-height: 28px;
	color: #000;
}
.pesquisa__interna{
	.titulo__interna{
		margin-bottom: 60px;
	}
	.row{
		margin-bottom: 30px;
	}
}
.esgotado__desc{
	margin-bottom: 10px;
}
.frete__input{
	display: block;
	width: 100%;
	background-color: #fff;
	color: #8f8f8a;
	height: auto;
	padding: 16px 15px 12px 15px;
    font-family: 'montserratregular';
	font-size: 13px;
	line-height: 17px;
	border: none;
    position: relative;
    font-weight: 600;
    letter-spacing: 0.6;
    font-style: italic;

	&:focus{
		outline: none;
	}

	&::placeholder{
		color: #8f8f8a;
	}
}
.segura__voltagem__listagem,
.segura__tamanho__listagem,
.segura__cor__listagem{
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    .cor__titulo{
        font-family: 'montserratbold';
        font-size: 13px;
        line-height: 13px;
        color: #000;
        margin-right: 5px;
    }
}
.esgotado__box{
	flex-direction: column;
	text-align: center;
	display: flex;
	align-items: center;

	.esgotado__form{
		width: 100%;
		flex-direction: column;
		text-align: center;
		display: flex;
		align-items: center;

		.frete__input{
			margin-bottom: 6px;
		}
	}

	.segura__esgotado{
		width: 100%;
		display: block;
		min-width: 100%;
	}

	.esgotado__form{
		flex-direction: column;
	}
}
.detalhe__tamanho__listagem{
	display: flex;
	flex-wrap: wrap;

	.tamanho__input{
		position: fixed;
		left: -100vw;

		&:checked{
			& ~ .tamanho__label{
				background-color: #009FE3;
				color: #fff;
                border: 1px solid #009FE3;
			}
		}
	}
	.tamanho__label{
		min-width: 24px;
        width: auto;
        padding: 0px 5px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #202020;
		border-radius: 24px;
		margin: 0px 5px;
		font-size: 14px;
		line-height: 14px;
		transition: all 0.3s linear;
		margin-bottom: 10px;
		cursor: pointer;
		font-family: 'montserratmedium';
        margin-bottom: 0px;

		&:hover{
			background-color: #009FE3;
			color: #fff;
		}
	}
    &.voltagem{
        .tamanho__label{
            font-size: 12px;
            line-height: 12px;
        }
    }
}
.caminhaozinho{
    display: inline-block;
    width: 18px;
    height: 13px;
    background-image: url('../images/caminhaozinho.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 5px;
}
.frete__btn{
	font-family: 'montserratbold';
    color: #009FE3;
	font-size: 14px;
	line-height: 14px;
	border: none;
	background-color: #fff;
	padding: 1px 18px 0px 18px;
	position: relative;

	&:focus{
		outline: none;
	}
}
.frete__link{
    display: flex;
    align-items: center;
    justify-content: flex-start;
	padding-left: 0px;
	font-size: 13px;
	line-height: 17px;
	padding-left: 0px;
	color: #000;
    padding-top: 5px;
    font-family: 'montserratregular';

	.icon{
		margin-right: 5px;
		margin-bottom: 5px;
	}
}
.produto__comprar,
.produto__indisponivel{
	margin-bottom: 20px;
}
.sociais__span{
    margin-bottom: 5px;
    font-family: 'montserratregular';
    color: #000;
    font-size: 13px;
    line-height: 17px;
}
.produto__sociais{
	display: flex;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #000;
		color: #fff;
		margin-right: 10px;
		transition: all 0.3s linear;

		&:hover{
            background-color: #000;
            color: #fff;
			text-decoration: none;
		}

		&:last-child{
			margin-right: 0px;
		}
	}
}
.row__produto__interna{
    padding-bottom: 50px;
    
    .col-lg-6{
        &:first-child{
            position: relative;
            z-index: 20;
        }
        &:last-child{
            position: relative;
            z-index: 10;
        }
    }
}
.segura__tab{
    padding-bottom: 20px;
    border-bottom: 4px solid #ececec;
    margin-bottom: 40px;
}
.segura__download__produto{
    padding-left: 30px;
}
.tab__produto{
	padding-top: 30px;
	border-top: 4px solid #ececec;
	margin-bottom: 20px;

	.nav-tabs{
        border-bottom: none;
        flex-wrap: wrap;
	}
	.nav-link{
		border: none;
		display: block;
        padding: 10px 20px 8px 20px;
		background-color: #d2d2d2;
		color: #000;
		font-size: 13px;
		line-height: 13px;
		transition: all 0.3s linear;
		font-family: 'montserratregular';
        margin: 0px 1px;
        min-width: 120px;
        text-align: center;
        border-radius: 0px;

		&.active{
			background-color: #e7e7e9;
            color: #000;
            font-family: 'montserratbold';
		}
	}
	.tab-content{
		padding: 20px 30px 30px 30px;
        background-color: #e7e7e9;
        color: #000;
	}
}
.titulo__avaliacoes{
	font-family: 'montserratbold';
	font-size: 24px;
	line-height: 28px;
	color: #000;
	margin-bottom: 26px;
}
.estrela__media{
	.avaliacao__media{
		font-family: 'montserratbold';
		font-size: 40px;
		line-height: 40px;
		color: #000;
		margin-right: 15px;
	}
	.numero__avaliacoes{
		color: #000;
		font-size: 12px;
		line-height: 12px;
		margin-left: 10px;
	}
}
.avaliacao__card{
	padding: 20px 0px;
	border-bottom: 1px solid #e1e1e1;

	&:last-child{
		border-bottom: none;
	}

	.avaliacao__header{
		margin-bottom: 15px;

		.numero__avaliacoes{
			color: #000;
			font-size: 12px;
			line-height: 12px;
			margin-left: 10px;
		}
	}
}
.comentario{
	margin-bottom: 8px;
    color: #000;
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 10px;
}
.comentario__nome{
    color: darken(#000, 10%);
    font-family: 'montserratsemibold';
    font-size: 15px;
    line-height: 19px;
}
.produto__tab__titulo{
	font-family: 'montserratbold';
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 10px;
	color: #000;
}
.avaliar__btn{
	width: 126px;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	padding-top: 2px;
	color: #000;
	font-size: 13px;
	line-height: 13px;
	background-color: #fff;
	border: 2px solid #000;
	font-family: 'montserratregular';
	transition: all 0.3s linear;

	&:hover{
        background-color: #000;
        color: #fff;
	}

	&:focus{
		outline: none;
	}
}
.avaliacao__radio{
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.avaliacao__sub{
	font-size: 15px;
	line-height: 19px;
	margin-bottom: 15px;
    font-family: 'montserratmedium';
    font-weight: 600;
    color: #000;
}
.label__avaliacao{
	font-size: 15px;
	line-height: 18px;
	color: #000;
	position: relative;
	padding-left: 24px;
    padding-top: 2px;
    margin-right: 10px;
    font-family: 'montserratmedium';
    font-weight: 600;
    min-height: 20px;

	&:hover{
		cursor: pointer;

		&:before{
			opacity: 0.5;
		}
	}

	&:after{
		content: '';
		display: block;
		width: 20px;
		min-width: 20px;
		height: 20px;
		border: 2px solid #000;
		border-radius: 50%;
		position: absolute;
		left: 0px;
		top: 0px;
		transition: all 0.3s linear;
	}
    &:before{
        content: '';
        width: 20px;
        height: 20px;
        background-color: #000;
        border: 4px solid #fff;
        border-radius: 12px;
        top: 0px;
        left: 0px;
        transition: all 0.3s linear;
        opacity: 0;
        position: absolute;
    }
}
.radio__avaliacao{
	position: fixed;
	left: -100vw;

	&:checked{
		& ~ .label__avaliacao{
			&:before{
				opacity: 1;
			}
		}
	}
}
.avaliacao__header{
	display: flex;
	align-items: center;
}
.estrela__media{
	display: flex;
	align-items: center;
	padding-bottom: 30px;
	border-bottom: 1px solid #e1e1e1;
	margin-bottom: 10px;

	.rating__stars{
		font-size: 28px;
		line-height: 28px;
	}
}
.avaliacao__input{
	display: block;
	width: 100%;
	background-color: #e7e7e9;
	resize: none;
	border: none;
    padding: 15px;

	&:focus{
		outline: none;
	}
}
.avaliacao__escreva{
	font-family: 'montserratmedium';
    font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: #000;
	margin-bottom: 10px;
}
.avaliacao__titulo{
	font-family: 'montserratregular';
	font-size: 20px;
	line-height: 24px;
	color: #000;
	margin-bottom: 25px;
}
.segura__avaliar__btn{
    display: flex;
    justify-content: flex-end;
}
.segura__frete__detalhe{
	display: flex;
	align-items: center;
}
.produto__download{
    display: flex;
    align-items: center;
    color: #666666;

    &:hover{
        color: #666666;
    }

    .produto__download__icone{
        margin-right: 10px;
    }
}
.frete__detalhe{
	flex-grow: 1;
}
.visualizar__carousel{
	text-align: center;

	.carousel-indicators{
		position: relative;
		text-indent: 1;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin-left: 0px;
		margin-right: 0px;
		padding-top: 20px;

		li{
			text-indent: 1px;
			width: 54px;
			height: 54px;
			min-width: 54px;
			opacity: 1;
			position: relative;
			margin: 0px 10px;
			border: none;

			&.active{
				&:after{
					opacity: 0.6;
				}
			}

			&:after{
				width: 100%;
				height: 100%;
				content: '';
				position: absolute;
				z-index: 2;
				top: 0px;
                left: 0px;
                background-color: #009FE3;
				opacity: 0;
				transition: all 0.3s linear;
			}

		}
	}
}
.produto__detalhes__botoes{
	display: flex;
	justify-content: center;
}
.produto__detalhe__botao{
	display: flex;
	width: 100%;
	height: 50px;
	align-items: center;
	text-align: center;
	justify-content: center;
	border: none;
	background-color: #009FE3;
	color: #fff;
	font-family: 'montserratsemibold';
	font-size: 15px;
	line-height: 15px;
	margin: 0px 10px;
	transition: all 0.3s linear;
	letter-spacing: -0.4px;
    padding: 0px 5px;
    border-radius: 0px;

	&:focus{
		outline: none;
	}

	&:hover{
		background-color: darken(#009FE3 , 10%);
		color: #fff;
        text-decoration: none;
	}
}
.sociais__produto{
    margin-bottom: 10px;
    padding-top: 10px;
}
.sociais__produto__span{
    font-family: 'montserratregular';
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
}
.sociais__produto__nav{
    display: flex;

    a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background-color: #000;
        color: #fff;
        border-radius: 50%;
        margin: 0px 5px;
        transition: all 0.3s linear;

        &:hover{
            text-decoration: none;
            background-color: #009FE3;
            color: #fff;
        }
    }
}
#modal__configuracao{
	.modal-dialog{
		width: 100%;
		max-width: 1110px;
	}
	.modal-content{
		padding: 10px 30px 50px 30px;

		.modal-header,
		.modal-body{
			padding: 0px;
			border: none;
		}
		.modal-header{
			display: flex;
			justify-content: flex-end;
			margin-bottom: 5px;
		}
	}
	.titulo__secao{
		margin-bottom: 10px;
	}
	.configuracao__desc{
		text-align: justify;
		margin-bottom: 22px;
	}
	.form-group{
		margin-bottom: 8px;

		&:nth-child(5) {
			grid-column: 1 / 3;
		}
	}
	.configuracao__btn{
		grid-column: 1 / 3;
		display: flex;
		justify-content: center;
		padding-top: 10px;
	}
}
.btn__fechar{
	font-family: 'montserratregular';
	font-size: 30px;
	line-height: 30px;
	color: #000;
	padding: 0px;
	border: none;
	background-color: transparent;

	&:focus{
		outline: none;
	}
}
.configuracao__form{
	display: grid;
	grid-template-columns: 50% 50%;
	column-gap: 20px;
}
.produto__detalhes__carrinho{
    padding: 10px 0px;
}
.video__link{
    display: flex;
    align-items: center;
    font-family: 'montserratlight';
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 12px;

    &:hover{
        text-decoration: none;

        .video__link__span{
            text-decoration: underline;
        }
    }

    .video__link__span{
        color: #666666;
    }

    .video__icone{
        margin-right: 10px;
        display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #000;
		color: #fff;
		transition: all 0.3s linear;
        font-size: 15px;
        line-height: 15px;
    }
}
@include media-breakpoint-up (xl) {
	.row__produto__interna{
		.col-lg-6{
			&:last-child{
				padding-left: 20px;
			}
		}
	}
}
@include media-breakpoint-up (lg) {
	.segure__download__btn{
		justify-content: flex-end;
	}
	.visualizar__mobile{
		display: none;
	}
}
@include media-breakpoint-down (md) {
	.produto__figure{
		text-align: center;
		margin-bottom: 20px;
	}
	.angulos__txt{
		text-align: center;
	}
	.row__miniaturas{
		justify-content: center;
		margin-bottom: 30px;

		.col__auto{
			&:first-child{
				margin-left: 10px;
			}
		}
	}
	.produto__indisponivel,
	.produto__comprar{
		max-width: 100%;
		width: 100%;
	}
	.avaliacao__form{
		padding-top: 30px;
	}
	// .row__produto__interna{
	// 	.col-lg-6{
	// 		&:first-child{
	// 			order: 2;
	// 		}
	// 		&:last-child{
	// 			order: 1;
	// 		}
	// 	}
	// }
	.produto__sociais{
		justify-content: center;
		margin-bottom: 30px;
	}
	.sociais__span{
		text-align: center;
	}
	.visualizar__desktop{
		display: none;
	}
}
@include media-breakpoint-down (sm) {
	.titulo__gostar{
		&:after{
			display: none;
		}
	}
}
@include media-breakpoint-down (xs) {
	.produto__detalhe__box{
		flex-direction: column;

		&:first-child{
			.produto__valor__box{
				justify-content: center;
				align-items: center;

				&:first-child{
					width: 100%;
					margin-bottom: 10px;
					padding-bottom: 10px;
					border-bottom: 2px solid #d1d0d0;
					border-right: 0px;

				}
				&:last-child{
					width: 100%;
				}
			}
		}
	}
	.produto__detalhes__botoes{
		flex-direction: column;
		align-items: center;

		.produto__detalhe__botao{
			margin: 5px;
		}
	}
	.configuracao__form{
		display: block;
	}
}
